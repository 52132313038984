@mixin ion-alert() {
  ion-alert[class*="sc-ion-alert"] .alert-wrapper {
    --width: auto;
    --min-width: 280px;
    --max-width: 90vw;

    gap: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 8px;

    @media screen and (min-width: 992px) {
      --min-width: 400px;
      --max-width: 600px;
    }

    .alert-head,
    .alert-message,
    .alert-button-group {
      padding: 0;
    }

    .alert-head {
      .alert-title {
        color: var(--ion-color-step-900);
      }
    }

    .alert-message {
      color: var(--ion-text-color);
    }

    .alert-button-group {
      row-gap: 0.25rem;
      column-gap: 0.5rem;
      margin-top: 0.75rem;

      &.alert-button-group-vertical {
        // prevent column direction when there are 3 or more alert buttons
        flex-direction: row !important;
      }
    }

    button.alert-button {
      width: 100%;
      padding: 6px 10px;
      border-radius: 8px;
      margin: 0;
      box-shadow: none;

      .alert-button-inner {
        justify-content: center;
        text-transform: none;
      }

      @media screen and (min-width: 992px) {
        width: auto;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }

      &.primary {
        color: var(--ion-color-primary-contrast);
        background-color: var(--ion-color-primary);

        &:hover::after {
          background: var(--ion-color-primary-contrast);
          opacity: 0.08;
        }
      }

      &.secondary {
        color: var(--ion-color-light-contrast);
        background-color: var(--ion-background-color);

        &:hover::after {
          background: var(--ion-color-light-contrast);
          opacity: 0.08;
        }
      }

      &.danger {
        color: var(--ion-color-danger-shade);
        background-color: var(--ion-background-color);

        &:hover::after {
          background: var(--ion-color-danger);
          opacity: 0.08;
        }
      }
    }
  }
}
