/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Import fonts
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~@ionic/angular/css/ionic.bundle.css";

// Theme customization
@import "theme/theme";

@import "theme/shared.scss";

@include ion-alert();

.cursor-point,
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.text-underline {
  text-decoration: underline;
}

.text-button {
  color: var(--text-link);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--text-link-hover);
  }
}

.d-flex {
  display: flex;
}

.g-0_5 {
  gap: 0.5rem;
}

.mat-mdc-progress-bar {
  border-radius: 2px;
}
.mdc-linear-progress__bar.mdc-linear-progress__primary-bar,
.mdc-linear-progress__bar.mdc-linear-progress__secondary-bar,
.mdc-linear-progress__bar-inner {
  border-color: var(--border-selected) !important;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(19, 91, 236, 0.2) !important;
}

div.mat-mdc-menu-panel {
  min-width: 100px;
  max-width: 280px;
  background-color: var(--background-base);
  border: 1px solid var(--border-base);
  border-radius: 0.25rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1) !important;
  margin: 2px 0;

  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    padding: 0.25rem !important;

    .mat-mdc-menu-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex: 1;
      gap: 0.5rem;
      min-height: 32px;
      min-width: 64px;
      text-decoration: none;
      color: var(--text-secondary);
      background-color: transparent;
      border: none;
      border-radius: 0.125rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      user-select: none;

      .mat-mdc-menu-item-text {
        @extend .label-md;
        -moz-osx-font-smoothing: auto !important;
        -webkit-font-smoothing: auto !important;
      }

      .mat-icon {
        color: var(--icon-secondary);
        text-align: center;
        margin-left: -0.25rem;
        margin-right: 0;
      }

      &:hover {
        background-color: var(--background-hover);
      }

      &:active {
        background-color: var(--background-active);
      }

      &[rs-action="destructive"] {
        color: var(--destructive);

        .mat-icon {
          color: var(--destructive);
        }

        &:hover {
          background-color: var(--destructive-transparent-hover);
        }
        &:active {
          background-color: var(--destructive-transparent-active);
        }
      }
    }

    hr {
      color: var(--border-base);
      margin: 0.125rem -0.25rem;
      opacity: 1;
    }
  }
}
