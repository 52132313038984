// // To make use of Bootstrap handy responsive mixins
// @import "./third-party/override-bootstrap-variables";
// // Bootstrap Required
// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";

@import "./typography.scss";

// @import "palettes/shared";
@import "./theme-variables";
// // imports all color tokens from our design system
// @import "../theme/rs-color-tokens";

// @import "./mixins/accordion";
@import "./mixins/alert";
@import "./mixins/button";
// @import "./mixins/button-group";
@import "./mixins/card";
// @import "./mixins/dialog";
// @import "./mixins/elevations";
// @import "./mixins/flared-tabs";
// @import "./mixins/layout";
// @import "./mixins/page-layout";
// @import "./mixins/popover";
// @import "./mixins/radio";
@import "./mixins/segment";
// @import "./mixins/stepper";
// @import "./mixins/tab-group";
@import "./mixins/table";
@import "./mixins/tabs";
@import "./mixins/toolbar";
// @import "./mixins/tab-group-basic-styles";
// @import "./mixins/toggle";
// @import "./mixins/truncate-text";

@import "./mixins/inputs/auto-complete";
@import "./mixins/inputs/checkbox";
// @import "./mixins/inputs/chips";
@import "./mixins/inputs/form-field";
// @import "./mixins/inputs/form-group";
@import "./mixins/inputs/input";
// @import "./mixins/inputs/radio";
// @import "./mixins/inputs/rating";
@import "./mixins/inputs/select";
@import "./mixins/inputs/textarea";

/* temp variables until no longer used */
// :host {
//   --page-title-color: var(--text-primary);
//   --page-padding: 60px 40px;
//   --page-spacing: 10px;
//   --section-title-color: var(--text-primary);

//   --table-text-color: var(--text-tertiary);

//   --status-active-color: var(--success);
//   --status-inactive-color: var(--error);
// }
/* ---------- */
