/*
 * Application global variables.
 */
$time-icon: 1;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// RTS
:root {
  // RS color tokens
  --background-base: #ffffff;
  --background-surface: #f7f9fa;
  --background-minimal: #eff2f5;
  --background-subtle: #e0e5eb;
  --background-moderate: #c1cad7;
  --background-strong: #8395af;
  --background-contrast: #28313e;
  --background-transparent: rgba(255, 255, 255, 0);
  --background-hover: #f7f9fa;
  --background-active: #eff2f5;
  --background-selected: #0f49bd;

  --border-base: #e0e5eb;
  --border-minimal: #c1cad7;
  --border-subtle: #a2b0c3;
  --border-moderate: #8395af;
  --border-strong: #50627c;
  --border-contrast: #14191f;
  --border-hover: #637b9c;
  --border-selected: #135bec;

  --text-primary: #14191f;
  --text-secondary: #28313e;
  --text-tertiary: #3c4a5d;
  --text-subtle: #50627c;
  --text-minimal: #8395af;
  --text-oncontrast: #ffffff;
  --text-selected: #0f49bd;
  --text-link: #0f49bd;
  --text-link-hover: #0b378e;
  --text-link-active: #08255e;

  --icon-primary: #14191f;
  --icon-secondary: #3c4a5d;
  --icon-tertiary: #637b9c;
  --icon-minimal: #a2b0c3;
  --icon-oncontrast: #ffffff;
  --icon-selected: #0f49bd;

  --secondary-heavy: #28313e;
  --secondary-strong: #3c4a5d;
  --secondary: #50627c;
  --secondary-moderate: #637b9c;
  --secondary-subtle: #8395af;
  --secondary-minimal: #a2b0c3;
  // --secondary-hover: ;
  // --secondary-active: ;
  --secondary-transparent-hover: rgba(193, 202, 215, 0.2);
  --secondary-transparent-active: rgba(193, 202, 215, 0.4);

  --general-moderate: #28313e;
  --general: #50627c;
  --general-subtle: #e0e5eb;

  --success-moderate: #15472c;
  --success: #298e58;
  --success-subtle: #d0f1df;

  --warning-moderate: #614a05;
  --warning: #c2940a;
  --warning-subtle: #fcf1cf;

  --error-moderate: #521414;
  --error: #cc3333;
  --error-subtle: #f5d6d6;

  --info-moderate: #08255e;
  --info: #135bec;
  --info-subtle: #d0defb;

  // ion colors
  --ion-text-color: #14191f;
  --ion-text-color-rgb: 20, 25, 31;

  --ion-color-primary: #1c7737;
  --ion-color-primary-rgb: 34, 139, 34;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1e7a1e;
  --ion-color-primary-tint: #389738;

  --ion-color-secondary: #64ad64;
  --ion-color-secondary-rgb: 100, 173, 100;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #589858;
  --ion-color-secondary-tint: #74b574;

  --ion-color-tertiary: #3880ff;
  --ion-color-tertiary-rgb: 56, 128, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3171e0;
  --ion-color-tertiary-tint: #4c8dff;

  --ion-color-success: #298e58;
  --ion-color-success-rgb: 41, 142, 88;
  --ion-color-success-contrast: #14191f;
  --ion-color-success-contrast-rgb: 20, 25, 31;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #061b06;
  --ion-color-dark-rgb: 6, 27, 6;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #051805;
  --ion-color-dark-tint: #1f321f;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: var(--ion-text-color);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #fff;
  --ion-background-color-contrast: #000;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-background-color-step-50: #f2f2f2;
  --ion-background-color-step-100: #e6e6e6;
  --ion-background-color-step-150: #d9d9d9;
  --ion-background-color-step-200: #ccc;
  --ion-background-color-step-250: #bfbfbf;
  --ion-background-color-step-300: #b3b3b3;
  --ion-background-color-step-350: #a6a6a6;
  --ion-background-color-step-400: #999;
  --ion-background-color-step-450: #8c8c8c;
  --ion-background-color-step-500: #808080;
  --ion-background-color-step-550: #737373;
  --ion-background-color-step-600: #666;
  --ion-background-color-step-650: #595959;
  --ion-background-color-step-700: #4d4d4d;
  --ion-background-color-step-750: #404040;
  --ion-background-color-step-800: #333;
  --ion-background-color-step-850: #262626;
  --ion-background-color-step-900: #191919;
  --ion-background-color-step-950: #0d0d0d;
  --ion-background-color-step-1000: #000;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #ccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
  --ion-color-step-1000: #000;

  --ion-color-login-input: #e6e6e6;
  --ion-color-login-text: #191919;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  }
}

/*
 * Dark Colors
 * ------------------
 */

body.dark {
  // RS color tokens
  --background-base: #111111;
  --background-surface: #14191f;
  --background-minimal: #28313e;
  --background-subtle: #3c4a5d;
  --background-moderate: #50627c;
  --background-strong: #8395af;
  --background-contrast: #c1cad7;
  --background-transparent: rgba(0, 0, 0, 0);
  --background-hover: rgba(40, 49, 62, 0.2);
  --background-active: rgba(40, 49, 62, 0.4);
  --background-selected: #135bec;

  --border-base: #28313e;
  --border-minimal: #3c4a5d;
  --border-subtle: #50627c;
  --border-moderate: #637b9c;
  --border-strong: #a2b0c3;
  --border-contrast: #eff2f5;
  --border-hover: #8395af;
  --border-selected: #427cf0;

  --text-primary: #eff2f5;
  --text-secondary: #e0e5eb;
  --text-tertiary: #c1cad7;
  --text-subtle: #a2b0c3;
  --text-minimal: #637b9c;
  --text-oncontrast: #14191f;
  --text-selected: #135bec;
  --text-link: #427cf0;
  --text-link-hover: #719df4;
  --text-link-active: #a1bef7;

  --icon-primary: #eff2f5;
  --icon-secondary: #c1cad7;
  --icon-tertiary: #8395af;
  --icon-minimal: #50627c;
  --icon-oncontrast: #14191f;
  --icon-selected: #427cf0;

  --secondary-heavy: #c1cad7;
  --secondary-strong: #a2b0c3;
  --secondary: #8395af;
  --secondary-moderate: #637b9c;
  --secondary-subtle: #50627c;
  --secondary-minimal: #3c4a5d;
  // --secondary-hover: ;
  // --secondary-active: ;
  --secondary-transparent-hover: rgba(40, 49, 62, 0.2);
  --secondary-transparent-active: rgba(40, 49, 62, 0.4);

  --general-moderate: #e0e5eb;
  --general: #8395af;
  --general-subtle: #28313e;

  --success-moderate: #d0f1df;
  --success: #55ce8d;
  --success-subtle: #15472c;

  --warning-moderate: #fcf1cf;
  --warning: #f5c73d;
  --warning-subtle: #614a05;

  --error-moderate: #f5d6d6;
  --error: #d65c5c;
  --error-subtle: #521414;

  --info-moderate: #d0defb;
  --info: #427cf0;
  --info-subtle: #08255e;

  // ion colors
  --ion-color-primary: #149e3d;
  --ion-color-primary-rgb: 20, 158, 61;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0e8b34;
  --ion-color-primary-tint: #1fad4a;

  --ion-color-secondary: #64ad64;
  --ion-color-secondary-rgb: 100, 173, 100;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #589858;
  --ion-color-secondary-tint: #74b574;

  --ion-color-tertiary: #3880ff;
  --ion-color-tertiary-rgb: 56, 128, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3171e0;
  --ion-color-tertiary-tint: #4c8dff;

  --ion-color-success: #55ce8d;
  --ion-color-success-rgb: 85, 206, 141;
  --ion-color-success-contrast: #14191f;
  --ion-color-success-contrast-rgb: 20, 25, 31;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-background-color: #000000;
  --ion-background-color-contrast: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-background-color-step-50: #0d0d0d;
  --ion-background-color-step-100: #1a1a1a;
  --ion-background-color-step-150: #262626;
  --ion-background-color-step-200: #333333;
  --ion-background-color-step-250: #404040;
  --ion-background-color-step-300: #4d4d4d;
  --ion-background-color-step-350: #595959;
  --ion-background-color-step-400: #666666;
  --ion-background-color-step-450: #737373;
  --ion-background-color-step-500: #808080;
  --ion-background-color-step-550: #8c8c8c;
  --ion-background-color-step-600: #999999;
  --ion-background-color-step-650: #a6a6a6;
  --ion-background-color-step-700: #b3b3b3;
  --ion-background-color-step-750: #bfbfbf;
  --ion-background-color-step-800: #cccccc;
  --ion-background-color-step-850: #d9d9d9;
  --ion-background-color-step-900: #e6e6e6;
  --ion-background-color-step-950: #f2f2f2;

  --ion-text-color: #eff2f5;
  --ion-text-color-rgb: 239, 242, 245;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  // --ion-item-background: #000000;

  --ion-border-color: var(--ion-color-step-250);
  --ion-card-background: var(--ion-color-step-100);
  --ion-item-background: transparent;

  --ion-color-login-input: #1a1a1a;
  --ion-color-login-text: #e6e6e6;

  ion-modal,
  ion-alert {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md-drppicker,
  .calendar-table {
    background-color: var(--ion-background-color);
    border-color: var(--ion-color-step-250);
  }
  .md-drppicker th.available {
    background-color: var(--ion-color-step-650);
  }
  .md-drppicker td.off {
    background-color: var(--ion-color-step-200);
  }
  .md-drppicker td.available.off:hover {
    color: var(--ion-color-step-700);
  }
  .md-drppicker td.available:hover {
    color: var(--ion-color-step-200);
  }
  .md-drppicker td.active:hover {
    color: var(--ion-color-step-650);
  }

  .cal-month-view {
    background-color: var(--ion-background-color);
  }

  .cal-month-view .cal-cell-row:hover {
    background-color: var(--ion-color-step-100);
  }
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--ion-color-step-200);
  }
  .cal-month-view .cal-days {
    border-color: var(--ion-color-step-300);
  }
  .cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: var(--ion-color-step-300);
  }
  .cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: var(--ion-color-step-300);
  }

  .cal-month-view .cal-day-cell.cal-today {
    background-color: var(--ion-color-step-300);
  }
  .cal-month-view .cal-day-cell.cal-drag-over {
    background-color: var(--ion-color-step-300) !important;
  }
  .cal-month-view .cal-open-day-events {
    color: #fff;
    background-color: var(--ion-color-step-500);
    -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
  }

  .cal-week-view {
    background-color: var(--ion-background-color);
    border-top: solid 1px var(--ion-color-step-200);
  }
  .cal-week-view .cal-day-headers {
    border-color: var(--ion-color-step-200);
    border-top: 0;
  }
  .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right-color: var(--ion-color-step-200);
  }
  .cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: var(--ion-color-step-200);
  }
  .cal-week-view .cal-day-headers .cal-header:hover,
  .cal-week-view .cal-day-headers .cal-drag-over {
    background-color: var(--ion-color-step-100);
  }
  .cal-week-view .cal-day-column {
    border-left-color: var(--ion-color-step-200);
  }
  .cal-week-view .cal-event {
    background-color: var(--ion-color-step-400);
    border-color: var(--ion-color-step-200);
    color: var(--ion-color-dark);
  }
  .cal-week-view .cal-all-day-events {
    border-color: var(--ion-color-step-200);
  }
  .cal-week-view .cal-header.cal-today {
    background-color: var(--ion-color-step-400);
  }
  .cal-week-view .cal-time-events {
    border-color: var(--ion-color-step-200);
  }
  .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: var(--ion-color-step-100);
  }
  .cal-week-view .cal-hour-odd {
    background-color: var(--ion-color-step-300);
  }
  .cal-week-view .cal-drag-over .cal-hour-segment {
    background-color: var(--ion-color-step-100);
  }
  .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: var(--ion-color-step-200);
  }

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: var(--ion-text-color);
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  input[type="time"]::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  }
}

:root.ios {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}

ion-content {
  --keyboard-offset: var(--ion-safe-area-bottom);
}

// material variable overrides
:root {
  --mdc-checkbox-selected-checkmark-color: unset;
}
