/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@import "./palettes/palette-0/root";

.cursor-pointer {
  cursor: pointer;
}

.text-primary {
  color: #007bff !important;
}
.text-success {
  color: #28a745 !important;
}
.text-danger {
  color: #dc3545 !important;
}
.text-secondary {
  color: #6c757d !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-info {
  color: #17a2b8 !important;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.m-5 {
  margin: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.m-15 {
  margin: 15px;
}
.p-5 {
  padding: 5;
}
.pr-5 {
  padding-right: 5;
}
.pl-5 {
  padding-left: 5;
}

.w-10 {
  width: 10%;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.clear {
  clear: both;
}
input,
textarea {
  color: black;
}

.dark .link {
  color: lightblue;
  text-decoration: underline;
  cursor: pointer;
}
.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 50%;
}

.container {
  display: flex;
}
.container-coloumn {
  display: flex;
  flex-direction: column;
}
.container-wrap {
  display: flex;
  flex-wrap: wrap;
}
.col {
  flex: 1;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 20;
}

.sticky-bottom {
  position: sticky;
  bottom: 0px;
  z-index: 20;
}

.sticky2 {
  position: sticky;
  top: 55px;
  z-index: 10;
}
// Table from bootstrap
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.grid,
.list {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.grid ion-row.header,
list ion-item.header {
  vertical-align: bottom;
  border-bottom: 2px solid var(--ion-color-step-250);
  background-color: var(--ion-color-light);
  color: var(--ion-color-dark);
  position: sticky;
  top: 0px;
  z-index: 10;
}

.grid ion-row,
.list ion-item {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid var(--ion-color-step-250);
}

.grid-striped ion-row.row:nth-of-type(odd),
.list-striped ion-item.row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.print {
  display: none;
}
@media print {
  ion-menu,
  ion-footer,
  ion-header {
    display: none;
  }
  .doNotPrint {
    display: none;
  }
  .print {
    display: block;
  }
}

ion-alert textarea {
  height: 120px;
  border: 3px solid #cccccc !important;
  padding: 5px !important;
  font-family: Tahoma, sans-serif;
}

.pill {
  border: none;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
}

.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
