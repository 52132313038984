// display and headings
.display-xl {
  font-size: 64px;
  line-height: 80px;
  font-weight: 700;
  letter-spacing: normal !important;
}
.display-lg {
  font-size: 48px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: normal !important;
}
.display-md {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  letter-spacing: normal !important;
}
.display-sm {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: normal !important;
}
.display-xs {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: normal !important;
}

.heading-h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.heading-h2 {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.heading-h3 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.heading-h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: normal !important;
}

// body content
.body-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.body-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.body-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.body-xs {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: normal !important;
}

.body-emphasized-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.body-emphasized-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.body-emphasized-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.body-emphasized-xs {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: normal !important;
}

.label-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.label-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.label-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.label-xs {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  letter-spacing: normal !important;
}

.label-emphasized-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.label-emphasized-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.label-emphasized-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: normal !important;
}
.label-emphasized-xs {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  letter-spacing: normal !important;
}

.button-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: normal !important;
}
.button-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: normal !important;
}
.button-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: normal !important;
}

.input-sm {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.input-md {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: normal !important;
}
.input-lg {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: normal !important;
}

.table-header {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: normal !important;
}

.table-cell {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: normal !important;
}
